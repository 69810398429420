<template>
    <section v-if="Object.keys(pricing).length" id="pricing-plan">
        <b-overlay
            :show="loading"
            variant="transperant"
            opacity="1" blur="5px"
            spinner-variant="primary"
        >
            <!-- title text and switch button -->
            <div class="text-center">
                <h1 class="mt-5">
                    Pricing Plans
                </h1>
                <p class="mb-2 pb-75">
                    All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit
                    your needs.
                </p>
                <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
                    <h6 class="mr-1 mb-0">
                        Monthly
                    </h6>
                    <b-form-checkbox id="priceSwitch" v-model="status" name="price-switch" value="annually"
                        unchecked-value="monthly" switch @input="tooglePlan" />
                    <h6 class="ml-50 mb-0">
                        Annually
                    </h6>
                </div>
            </div>
            <!--/ title text and switch button -->

            <!-- pricing plan cards -->
            <b-row class="pricing-card">
                <b-col cols="12" class="mx-auto">
                    <b-row>
                        <b-col md="3">
                            <b-card align="center">
                                <!-- img -->
                                <b-img v-if="pricing.basicPlan.img" :src="pricing.basicPlan.img" class="mb-2 mt-5"
                                    alt="basic svg img" />
                                <!--/ img -->
                                <h3>{{ pricing.basicPlan.title }}</h3>
                                <b-card-text>
                                    {{ pricing.basicPlan.subtitle }}
                                </b-card-text>

                                <!-- annual plan -->
                                <div class="annual-plan">
                                    <div class="plan-price mt-2">
                                        <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                        <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow
            ? pricing.basicPlan.monthlyPrice : pricing.basicPlan.yearlyPlan.perMonth
                                            }}</span>
                                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                    </div>
                                    <small v-show="!monthlyPlanShow" class="annual-pricing text-muted">USD {{
            pricing.basicPlan.yearlyPlan.totalAnual }} / year</small>
                                </div>
                                <!--/ annual plan -->

                                <!-- plan benefit -->
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item v-for="(data, index) in pricing.basicPlan.planBenefits" :key="index">
                                        {{ data }}
                                    </b-list-group-item>
                                </b-list-group>
                                <!--/ plan benefit -->

                                <!-- buttons -->
                                <b-button v-if="user.account.plan.name == pricing.basicPlan.title" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2"
                                    variant="outline-success">
                                    Your current plan
                                </b-button>
                                <b-button v-else @click="cancelSubscription" v-ripple.400="'rgba(255, 255, 255, 0.15)'" block class="mt-2" variant="outline-warning">
                                    Downgrade
                                </b-button>
                            </b-card>
                        </b-col>
                        <b-col md="3">
                            <b-card align="center">
                                <!-- img -->
                                <b-img v-if="pricing.standardPlan.img" :src="pricing.standardPlan.img" class="mb-1"
                                    alt="svg img" />
                                <!--/ img -->
                                <h3>{{ pricing.standardPlan.title }}</h3>
                                <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

                                <!-- annual plan -->
                                <div class="annual-plan">
                                    <div class="plan-price mt-2">
                                        <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                        <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow
            ? pricing.standardPlan.monthlyPrice : pricing.standardPlan.yearlyPlan.perMonth
                                            }}</span>
                                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                    </div>
                                    <small v-show="!monthlyPlanShow" class="annual-pricing text-muted">USD {{
            pricing.standardPlan.yearlyPlan.totalAnual }} / year</small>
                                </div>
                                <!--/ annual plan -->

                                <!-- plan benefit -->
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item v-for="(data, index) in pricing.standardPlan.planBenefits"
                                        :key="index">
                                        {{ data }}
                                    </b-list-group-item>
                                </b-list-group>
                                <!--/ plan benefit -->

                                <!-- buttons -->
                                <b-button v-if="user.account.plan.name == pricing.standardPlan.title" @click="cancelSubscription" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2"
                                    variant="outline-danger">
                                    Cancel Subscription
                                </b-button>
                                <b-button v-else :to="`checkout?plan=Standard&period=${status}`" v-ripple.400="'rgba(255, 255, 255, 0.15)'" block class="mt-2" variant="outline-primary">
                                    Upgrade
                                </b-button>
                            </b-card>
                        </b-col>
                        <b-col md="3">
                            <b-card class="popular" align="center">
                                <div class="pricing-badge text-right">
                                    <b-badge variant="light-primary" pill>
                                        Popular
                                    </b-badge>
                                </div>
                                <!-- img -->
                                <b-img v-if="pricing.enterprisePlan.img" :src="pricing.enterprisePlan.img" class="mb-2"
                                    alt="enterprise svg img" />
                                <!--/ img -->
                                <h3>{{ pricing.enterprisePlan.title }}</h3>
                                <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

                                <!-- annual plan -->
                                <div class="annual-plan">
                                    <div class="plan-price mt-2">
                                        <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                        <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow
            ? pricing.enterprisePlan.monthlyPrice :
            pricing.enterprisePlan.yearlyPlan.perMonth
                                            }}</span>
                                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                    </div>
                                    <small v-show="!monthlyPlanShow" class="annual-pricing text-muted">USD {{
            pricing.enterprisePlan.yearlyPlan.totalAnual }} / year</small>
                                </div>
                                <!--/ annual plan -->

                                <!-- plan benefit -->
                                <b-list-group v-for="(data, index) in pricing.enterprisePlan.planBenefits" :key="index"
                                    class="list-group-circle text-left">
                                    <b-list-group-item>
                                        {{ data }}
                                    </b-list-group-item>
                                </b-list-group>
                                <!--/ plan benefit -->

                                <!-- buttons -->
                                <b-button v-if="user.account.plan.name == pricing.enterprisePlan.title" @click="cancelSubscription" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2"
                                    variant="outline-danger">
                                    Cancel Subscription
                                </b-button>
                                <b-button v-else :to="`checkout?plan=Business&period=${status}`" v-ripple.400="'rgba(255, 255, 255, 0.15)'" block class="mt-2" variant="primary">
                                    Upgrade
                                </b-button>
                            </b-card>
                        </b-col>
                        <b-col md="3">
                            <b-card align="center">
                                <!-- img -->
                                <b-img v-if="pricing.agencyPlan.img" :src="pricing.agencyPlan.img" class="mb-2"
                                    alt="enterprise svg img" />
                                <!--/ img -->
                                <h3>{{ pricing.agencyPlan.title }}</h3>
                                <b-card-text>{{ pricing.agencyPlan.subtitle }}</b-card-text>

                                <!-- annual plan -->
                                <div class="annual-plan">
                                    <div class="plan-price mt-2">
                                        <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                        <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow
            ? pricing.agencyPlan.monthlyPrice :
            pricing.agencyPlan.yearlyPlan.perMonth
                                            }}</span>
                                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                    </div>
                                    <small v-show="!monthlyPlanShow" class="annual-pricing text-muted">USD {{
            pricing.agencyPlan.yearlyPlan.totalAnual }} / year</small>
                                </div>
                                <!--/ annual plan -->

                                <!-- plan benefit -->
                                <b-list-group v-for="(data, index) in pricing.agencyPlan.planBenefits" :key="index"
                                    class="list-group-circle text-left">
                                    <b-list-group-item>
                                        {{ data }}
                                    </b-list-group-item>
                                </b-list-group>
                                <!--/ plan benefit -->

                                <!-- buttons -->
                                <b-button v-if="user.account.plan.name == pricing.agencyPlan.title" @click="cancelSubscription" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2"
                                    variant="outline-danger">
                                    Cancel Subscription
                                </b-button>
                                <b-button v-else :to="`checkout?plan=Agency&period=${status}`" v-ripple.400="'rgba(255, 255, 255, 0.15)'" block class="mt-2" variant="outline-primary">
                                    Upgrade
                                </b-button>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <!--/ pricing plan cards -->

            <!-- pricing free trial -->
            <div class="pricing-free-trial">
                <b-row>
                    <b-col lg="10" offset-lg="3" class="mx-auto">
                        <div class="pricing-trial-content d-flex justify-content-between">
                            <div class="text-center text-md-left mt-3">
                                <h3 class="text-primary">
                                    Still not convinced? Start with a 14-day FREE trial!
                                </h3>
                                <h5>You will get full access to with all the features for 14 days.</h5>
                                <b-button @click="requestFreeTrial" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mt-lg-3">
                                    Request 14-day FREE trial
                                </b-button>
                            </div>

                            <!-- images -->
                            <b-img fluid :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                                class="pricing-trial-img" alt="svg img" />
                            <!--/ images -->
                        </div>
                    </b-col>
                </b-row>
            </div>
            <!--/ pricing free trial -->

            <!-- pricing faq -->
            <div class="pricing-faq">
                <h3 class="text-center">
                    FAQ's
                </h3>
                <p class="text-center">
                    Let us help answer the most common questions.
                </p>
                <b-row class="py-2">
                    <b-col lg="10" offset-lg="2" class="mx-auto">
                        <app-collapse accordion type="margin">

                            <app-collapse-item v-for="(data,index) in pricing.qandA" :key="index" :title="data.question">
                                {{ data.ans }}
                            </app-collapse-item>
                        </app-collapse>
                    </b-col>
                </b-row>
            </div>
            <!--/ pricing faq -->
        </b-overlay>
    </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
/* eslint-disable global-require */
export default {
    components: {
        ToastificationContent, BFormCheckbox, BButton, BCardText, BListGroup, BListGroupItem, BRow, BCol, BCard, BBadge, BOverlay, 
        BImg, AppCollapseItem, AppCollapse,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loading: true,
            status: 'monthly',
            monthlyPlanShow: true,
            pricing: {
                basicPlan: {
                    title: 'Free',
                    img: require('@/assets/images/illustration/Pot1.svg'),
                    subtitle: 'A simple start for everyone',
                    monthlyPrice: 0,
                    yearlyPlan: {
                        perMonth: 0,
                        totalAnual: 0,
                    },
                    planBenefits: [
                        '1 Brand',
                        '1 Member',
                        '2 Social Channels',
                        '10 Posts',
                        '20 Comments',
                        '1 Url Shortener',
                        '1 Social Profile (link in bio)',
                        '24/7 Support',
                    ],
                    popular: false,
                },
                standardPlan: {
                    title: 'Standard',
                    img: require('@/assets/images/illustration/Pot2.svg'),
                    subtitle: 'For small to medium businesses',
                    monthlyPrice: 9,
                    yearlyPlan: {
                        perMonth: 7.5,
                        totalAnual: 90,
                    },
                    planBenefits: [
                        '2 Brands',
                        '2 Members',
                        '20 Social Channels',
                        '200 Posts',
                        '400 Comments',
                        'Auto Reply AI Assistant',
                        '20 Urls Shortener',
                        '4 Social Profiles (link in bio)',
                        '24/7 Support',
                    ],
                    popular: false,
                },
                enterprisePlan: {
                    title: 'Business',
                    img: require('@/assets/images/illustration/Pot3.svg'),
                    subtitle: 'Solution for big organizations',
                    monthlyPrice: 19,
                    yearlyPlan: {
                        perMonth: 15.8,
                        totalAnual: 190,
                    },
                    planBenefits: [
                        '5 Brands',
                        '5 Members',
                        '50 Social Channels',
                        'Unlimeted Posts',
                        'Unlimeted Comments',
                        'Auto Reply AI Assistant',
                        'Unlimeted Urls Shortener',
                        'Unlimeted Social Profiles (link in bio)',
                        '24/7 Support',
                    ],
                    popular: true,
                },
                agencyPlan: {
                    title: 'Agency',
                    img: require('@/assets/images/illustration/Pot3.svg'),
                    subtitle: 'Solution for big Agency',
                    monthlyPrice: 99,
                    yearlyPlan: {
                        perMonth: 82.5,
                        totalAnual: 990,
                    },
                    planBenefits: [
                        '50 Brands',
                        '50 Members',
                        '500 Social Channels',
                        'Unlimeted Posts',
                        'Unlimeted Comments',
                        'Auto Reply AI Assistant',
                        'Unlimeted Urls Shortener',
                        'Unlimeted Social Profiles (link in bio)',
                        '24/7 Support',
                    ],
                    popular: false,
                },
                qandA: [
                    {
                        question: 'Does my subscription automatically renew?',
                        ans:
                            'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
                    },
                    {
                        question: 'Can I store the item on an intranet so everyone has access?',
                        ans:
                            'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
                    },
                    {
                        question: 'Am I allowed to modify the item that I purchased?',
                        ans:
                            'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
                    },
                ],
            },
            user: {
                account: {
                    brands_quota_monthly: 0,
                    channels_quota_monthly: 0,
                    comments_quota_monthly: 0,
                    members_quota_monthly: 0,
                    posts_quota_monthly: 0,
                    plan: {
                        name: ''
                    }
                }
            }
        }
    },
    created(){
        this.getUserInfo();
    },
    mounted(){
        if (this.$route.query.success == '1'){
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Subscription Upgraded',
                    icon: 'CheckIcon',
                    text: 'Your subscription was upgraded successfully',
                    variant: 'success',
                },
            })
        } else if (this.$route.query.success === '0') {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Warning !',
                    icon: 'AlertCircleIcon',
                    text: 'Something Went Wrong. Please try again',
                    variant: 'danger',
                },
            })
        }
    },
    methods: {
        async cancelSubscription(){
            this.loading = true
            await this.$swal({
                title: 'Are you sure?',
                text: "You want to cancel subscription!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, cancel subscription!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.post('/cancel_subscription/')
                    .then(response => {
                        console.log(response);
                        this.$swal({
                            icon: 'success',
                            title: 'Cancelled!',
                            text: 'Your Subscription has been cancelled.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.getUserInfo()
                    })
                    .catch(error => {
                        this.loading = false
                        console.log(error);
                    })
                } else {
                    this.loading = false
                }
            })
        },
        tooglePlan() {
            if (this.status === 'monthly') {
                this.monthlyPlanShow = true
            } else {
                this.monthlyPlanShow = false
            }
        },
        requestFreeTrial(){
            // posyt axios request trial
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: '14-day FREE trial Request',
                    icon: 'CheckIcon',
                    text: 'Your request was sent successfully, we will get back to you soon',
                    variant: 'success',
                },
            })
        },
        async getUserInfo(){
            await axios.get('/me/')
            .then(res => {
                this.loading = false
                console.log(res);
                this.user = res.data
                localStorage.setItem('brands_quota_monthly', res.data.account.brands_quota_monthly)
                localStorage.setItem('channels_quota_monthly', res.data.account.channels_quota_monthly)
                localStorage.setItem('comments_quota_monthly', res.data.account.comments_quota_monthly)
                localStorage.setItem('members_quota_monthly', res.data.account.members_quota_monthly)
                localStorage.setItem('posts_quota_monthly', res.data.account.posts_quota_monthly)

                localStorage.setItem('planid', res.data.account.plan.id)
                localStorage.setItem('planname', res.data.account.plan.name)

                this.$store.commit('auth/setPlan',
                    {
                        'id': res.data.account.plan.id,
                        'name': res.data.account.plan.name,
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
        }
    },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>